import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { EventsList } from "../components/EventsList";
import { PostsList } from "../components/PostsList";
import { RainbowMenu } from "../components/RainbowMenu";
import { graphql } from 'gatsby';
import { Sidebar } from "../components/Sidebar";
import { LogOpen } from "../components/LogOpen";
import { Helmet } from "react-helmet";

import "./styles.scss"

export default function IndexPage({ data }) {

  const events = data.allMdx.nodes.filter((n) => n.slug.startsWith('events'))
  const posts = data.allMdx.nodes.filter((n) => n.slug.startsWith('posts'))

  return <div>
    <LogOpen page="welcome" />

    <Helmet>
      <title>Willkommen bei SMAG</title>
      <meta name="description" content="Gruppe für schwule Jungs ab 30 Jahren in Nürnberg"></meta>
      <meta name="abstract" content="Wir sind die Gruppe für schwule Jungs im Alter ab 30 Jahren im Fliederlich e.V. Nürnberg und laden Dich ein zu geselligen Abenden, Theaterbesuchen, Outdoor-Aktivitäten, uvm."></meta>
    </Helmet>

    <RainbowMenu active="Willkommen"></RainbowMenu>

    <div className="box has-background-light">
      <h4 className="title is-4">Willkommen bei SMAG</h4>
      <div className="is-clearfix">
        <StaticImage src="../images/banner.jpg" alt="Startseite" className="ml-4 is-pulled-right" />
        <p>
          Du bist schwul, lebst in Nürnberg oder Umgebung und willst neue
          Leute kennen lernen?
        </p>
        <p>
          Wir sind die Gruppe für schwule Jungs im Alter ab 30 Jahren (kurz: SMAG) im Fliederlich
          e.V. Nürnberg und laden Dich ein zu gemeinsamen Unternehmungen, wie Gruppenabende, Biergarten,
          Party-Besuche, Städte-Touren, Wanderungen, Theaterbesuche, Kochabende <a href="/posts">und vieles mehr</a>.
        </p>
        <p className="mt-3">
          Komm' doch einfach mal bei einer unserer zahlreichen Veranstaltungen vorbei und lerne uns kennen.
          Wir freuen uns auf dich!
        </p>
        <p className="mt-3">
          Große Gruppenabende oder Veranstaltungen sind dir für den Einstieg zu viel? Dann schreib uns einfach an <a href="mailto:smag@fliederlich.de">smag@fliederlich.de</a> und
          lerne einen von uns <a href="/team">Teamern</a> vorab ungezwungen bei einem Kaffee in einem von Nürnbergs queeren Cafés kennen.
        </p>
      </div>
    </div>

    <div className="columns">
      <div className="column is-3">
        <Sidebar></Sidebar>
      </div>
      <div className="column is-9">
        <div className="mb-4">
          <EventsList events={events} />
        </div>
      </div>
    </div>
  </div>
}

export const pageQuery = graphql`
{
  allMdx {
    nodes {
      id
      excerpt
      slug
      frontmatter {
        title
        date
        thumbnail: image {
          childImageSharp {
            gatsbyImageData(
              width: 200
            )
          }
        }
        full: image {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
}

`